import React from 'react'
import Hero from '../sections/landing4/Hero'
import Feature from '../sections/landing4/Feature'
import Content1 from '../sections/landing4/Content1'
import Content2 from '../sections/landing4/Content2'
import Pricing from '../sections/landing4/Pricing'
import Faq from '../sections/landing4/Faq'
import CTA from '../sections/landing4/CTA'
import PageWrapper from '../components/PageWrapper'

const IndexPage = () => {
  return (
    <>
      <PageWrapper footerDark>
        <Hero />
        <Feature />
        <Content1 />
        <Content2 />
        <Pricing />
        <Faq />
        <CTA />
      </PageWrapper>
    </>
  )
}
export default IndexPage
